// extracted by mini-css-extract-plugin
export var a = "plasmic_consiglio_io_business-module--a--5R8Cz";
export var address = "plasmic_consiglio_io_business-module--address--M++PO";
export var all = "plasmic_consiglio_io_business-module--all--0pPrm";
export var blockquote = "plasmic_consiglio_io_business-module--blockquote---Ucag";
export var button = "plasmic_consiglio_io_business-module--button--Rq+IK";
export var code = "plasmic_consiglio_io_business-module--code--ct2Fo";
export var h1 = "plasmic_consiglio_io_business-module--h1--WSGxN";
export var h2 = "plasmic_consiglio_io_business-module--h2--h9VW3";
export var h3 = "plasmic_consiglio_io_business-module--h3--sL2lr";
export var h4 = "plasmic_consiglio_io_business-module--h4--V0KoT";
export var h5 = "plasmic_consiglio_io_business-module--h5--PavGT";
export var h6 = "plasmic_consiglio_io_business-module--h6--g1OHW";
export var img = "plasmic_consiglio_io_business-module--img--7sNG-";
export var input = "plasmic_consiglio_io_business-module--input--tFoo3";
export var li = "plasmic_consiglio_io_business-module--li--RhNb4";
export var ol = "plasmic_consiglio_io_business-module--ol--UAJ6Q";
export var p = "plasmic_consiglio_io_business-module--p--6BkCU";
export var plasmic_default__a = "plasmic_consiglio_io_business-module--plasmic_default__a--AvGsA";
export var plasmic_default__blockquote = "plasmic_consiglio_io_business-module--plasmic_default__blockquote--cGI4q";
export var plasmic_default__code = "plasmic_consiglio_io_business-module--plasmic_default__code--IM8Bz";
export var plasmic_default__component_wrapper = "plasmic_consiglio_io_business-module--plasmic_default__component_wrapper--mWlbF";
export var plasmic_default__h1 = "plasmic_consiglio_io_business-module--plasmic_default__h1--cVzbQ";
export var plasmic_default__h3 = "plasmic_consiglio_io_business-module--plasmic_default__h3--E40YI";
export var plasmic_default__h4 = "plasmic_consiglio_io_business-module--plasmic_default__h4--xvSxt";
export var plasmic_default__h5 = "plasmic_consiglio_io_business-module--plasmic_default__h5--lgHFc";
export var plasmic_default__h6 = "plasmic_consiglio_io_business-module--plasmic_default__h6--7Ynwp";
export var plasmic_default__inline = "plasmic_consiglio_io_business-module--plasmic_default__inline--78moe";
export var plasmic_default__ol = "plasmic_consiglio_io_business-module--plasmic_default__ol--QE0sv";
export var plasmic_default__pre = "plasmic_consiglio_io_business-module--plasmic_default__pre--RjKLh";
export var plasmic_default__ul = "plasmic_consiglio_io_business-module--plasmic_default__ul--Y8FgL";
export var plasmic_default_styles = "plasmic_consiglio_io_business-module--plasmic_default_styles--R5uXs";
export var plasmic_mixins = "plasmic_consiglio_io_business-module--plasmic_mixins--ANnQw";
export var plasmic_page_wrapper = "plasmic_consiglio_io_business-module--plasmic_page_wrapper--jrRo6";
export var plasmic_tokens = "plasmic_consiglio_io_business-module--plasmic_tokens--Ajs5v";
export var pre = "plasmic_consiglio_io_business-module--pre--9-orN";
export var root_reset = "plasmic_consiglio_io_business-module--root_reset--0jwoY";
export var select = "plasmic_consiglio_io_business-module--select--ycSXD";
export var span = "plasmic_consiglio_io_business-module--span--W2Fy-";
export var textarea = "plasmic_consiglio_io_business-module--textarea--sNbyl";
export var ul = "plasmic_consiglio_io_business-module--ul--0gG0Z";