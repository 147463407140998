// extracted by mini-css-extract-plugin
export var button___6JNi7 = "PlasmicFooterSection-module--button___6JNi7--Lya2B";
export var button__hjyIs = "PlasmicFooterSection-module--button__hjyIs--G8DW4";
export var button__iQerb = "PlasmicFooterSection-module--button__iQerb--0h2SL";
export var freeBox___4Owvo = "PlasmicFooterSection-module--freeBox___4Owvo--EMTNs";
export var freeBox___70Eaj = "PlasmicFooterSection-module--freeBox___70Eaj--9il7b";
export var freeBox__pBs8M = "PlasmicFooterSection-module--freeBox__pBs8M--HJtiI";
export var freeBox__qfBqZ = "PlasmicFooterSection-module--freeBox__qfBqZ--osqWd";
export var freeBox__uquMf = "PlasmicFooterSection-module--freeBox__uquMf--gHXVD";
export var link___2JB0L = "PlasmicFooterSection-module--link___2JB0L--zjWRK";
export var link__roa2W = "PlasmicFooterSection-module--link__roa2W--PW+sb";
export var root = "PlasmicFooterSection-module--root--XDEAu";
export var svg__bsdic = "PlasmicFooterSection-module--svg__bsdic--x9DkG";
export var svg__dcW8 = "PlasmicFooterSection-module--svg__dcW8--38qME";
export var svg__eSYt = "PlasmicFooterSection-module--svg__eSYt--cSkx0";
export var svg__hg07V = "PlasmicFooterSection-module--svg__hg07V--5JSxj";
export var svg__hn88B = "PlasmicFooterSection-module--svg__hn88B--u-nJK";
export var svg__nawvg = "PlasmicFooterSection-module--svg__nawvg--+e3rc";
export var svg__sDPmy = "PlasmicFooterSection-module--svg__sDPmy--itW1o";
export var svg__xLsgd = "PlasmicFooterSection-module--svg__xLsgd--0Y6sI";
export var svg__xgwW = "PlasmicFooterSection-module--svg__xgwW--1Paz7";