// extracted by mini-css-extract-plugin
export var __wab_slot = "PlasmicButton-module--__wab_slot--vqQXp";
export var __wab_slotStringWrapper = "PlasmicButton-module--__wab_slot-string-wrapper--7QomK";
export var __wab_text = "PlasmicButton-module--__wab_text--pMyXH";
export var root = "PlasmicButton-module--root--U5qlK";
export var rootbgDifference = "PlasmicButton-module--rootbgDifference--VoRB9";
export var rootcolor_blue = "PlasmicButton-module--rootcolor_blue--vnhUq";
export var rootcolor_darkGray = "PlasmicButton-module--rootcolor_darkGray--LrR4V";
export var rootcolor_green = "PlasmicButton-module--rootcolor_green--cOsZ-";
export var rootcolor_indigo = "PlasmicButton-module--rootcolor_indigo--89bBT";
export var rootcolor_navLink = "PlasmicButton-module--rootcolor_navLink--KNKb7";
export var rootcolor_red = "PlasmicButton-module--rootcolor_red--X688P";
export var rootcolor_white = "PlasmicButton-module--rootcolor_white--F7ud1";
export var rootextraSmallShadow = "PlasmicButton-module--rootextraSmallShadow--N1Ujo";
export var rootflat = "PlasmicButton-module--rootflat--sc-qS";
export var rooticonOnly = "PlasmicButton-module--rooticonOnly--RCgfE";
export var rootisDisabled = "PlasmicButton-module--rootisDisabled--zxs1D";
export var rootoutline = "PlasmicButton-module--rootoutline--4m8sw";
export var rootround = "PlasmicButton-module--rootround--e1QoS";
export var rootshowStartIcon = "PlasmicButton-module--rootshowStartIcon--+dbDu";
export var slotTargetChildren = "PlasmicButton-module--slotTargetChildren--EkAiz";
export var slotTargetChildrencolor_blue = "PlasmicButton-module--slotTargetChildrencolor_blue--MDEuF";
export var slotTargetChildrencolor_darkGray = "PlasmicButton-module--slotTargetChildrencolor_darkGray--LOD6P";
export var slotTargetChildrencolor_green = "PlasmicButton-module--slotTargetChildrencolor_green--3ItUr";
export var slotTargetChildrencolor_indigo = "PlasmicButton-module--slotTargetChildrencolor_indigo--a-ldT";
export var slotTargetChildrencolor_link = "PlasmicButton-module--slotTargetChildrencolor_link--eeFFq";
export var slotTargetChildrencolor_navLink = "PlasmicButton-module--slotTargetChildrencolor_navLink--j-RlQ";
export var slotTargetChildrencolor_red = "PlasmicButton-module--slotTargetChildrencolor_red--bE2sX";
export var slotTargetChildrenlarge = "PlasmicButton-module--slotTargetChildrenlarge--O8FZn";
export var slotTargetEndIcon = "PlasmicButton-module--slotTargetEndIcon--F9XTG";
export var slotTargetEndIconcolor_blue = "PlasmicButton-module--slotTargetEndIconcolor_blue--0WCXa";
export var slotTargetEndIconcolor_darkGray = "PlasmicButton-module--slotTargetEndIconcolor_darkGray--q8fwT";
export var slotTargetEndIconcolor_green = "PlasmicButton-module--slotTargetEndIconcolor_green--VUpuR";
export var slotTargetEndIconcolor_indigo = "PlasmicButton-module--slotTargetEndIconcolor_indigo--dth93";
export var slotTargetEndIconcolor_link = "PlasmicButton-module--slotTargetEndIconcolor_link--DjfVX";
export var slotTargetEndIconcolor_red = "PlasmicButton-module--slotTargetEndIconcolor_red--R9eB4";
export var slotTargetEndIconcolor_white = "PlasmicButton-module--slotTargetEndIconcolor_white--nRXZo";
export var slotTargetStartIcon = "PlasmicButton-module--slotTargetStartIcon--ntrp3";
export var slotTargetStartIconcolor_blue = "PlasmicButton-module--slotTargetStartIconcolor_blue--2r0E1";
export var slotTargetStartIconcolor_darkGray = "PlasmicButton-module--slotTargetStartIconcolor_darkGray--UiM80";
export var slotTargetStartIconcolor_green = "PlasmicButton-module--slotTargetStartIconcolor_green--oGFo1";
export var slotTargetStartIconcolor_indigo = "PlasmicButton-module--slotTargetStartIconcolor_indigo--FgUFh";
export var slotTargetStartIconcolor_link = "PlasmicButton-module--slotTargetStartIconcolor_link--16alU";
export var slotTargetStartIconcolor_red = "PlasmicButton-module--slotTargetStartIconcolor_red--kz1oj";
export var slotTargetStartIconcolor_white = "PlasmicButton-module--slotTargetStartIconcolor_white--ljGgC";
export var svg___3Ezaw = "PlasmicButton-module--svg___3Ezaw--NpQ3R";
export var svg__v4Pgi = "PlasmicButton-module--svg__v4Pgi--NgZ3T";